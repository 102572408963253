import Mock from "../mock";

const database = {
  information: {
    name: 'Allan Liu',
    aboutContent: "I am a front-end web developer. I write clean code and user-friendly interface designs. I enjoy creating data visualizations to find patterns and meaning behind large real-world datasets.",
    aboutDescription: "I am a front-end web developer with knowledge in full stack development. I specialize in usability and user experience interfaces. I enjoy hiking and creating data visualizations on my free time.",
    age: 28,
    phone: '',
    nationality: 'Canadian',
    language: 'English, Cantonese',
    email: 'allan.sf.liu@gmail.com',
    location: 'Victoria, British Columbia',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      pinterest: '',
      behance: '',
      dribbble: '',
      twitter: 'https://twitter.com/aLiu_ftw',
      linkedin: 'https://www.linkedin.com/in/liuallan/',
      github: 'https://github.com/LiuAllan'
    },
    brandImage: '/images/home-img.jpg',
    aboutImage: '/images/about-img.png',
    aboutImageLg: '/images/about-img-large.jpg',
    cvfile: '/files/allan_resume.pdf'
  },
  services: [
    {
      title: "UI/UX Design",
      icon: 'brush-alt',
      details: "I design user-friendly interfaces with a focus on usability and user experience. I want people to be able to locate things quickly and intuitively."
    },
    {
      title: "Front-end Development",
      icon: 'code',
      details: "I provide clean and readable code as well as documentation. I do testing to ensure the piece of software is optimized for performance and reliable."
    },
    {
      title: "Responsive Mobile apps",
      icon: 'mobile',
      details: `I design functional web applications that are compatible with mobile devices and large screens. I aim to focus on "mobile-first" design and accessibility best practices.`
    }
  ],
  // reviews: [
  //   {
  //     id: 1,
  //     content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
  //     author: {
  //       name: 'Burdette Turner',
  //       designation: 'Web Developer, Abc Company'
  //     }
  //   },
  //   {
  //     id: 2,
  //     content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
  //     author: {
  //       name: 'Susan Yost',
  //       designation: 'Client'
  //     }
  //   },
  //   {
  //     id: 3,
  //     content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
  //     author: {
  //       name: 'Irving Feeney',
  //       designation: 'Fiverr Client'
  //     }
  //   }
  // ],
  skills: [
    {
      title: "Vue",
      value: 90
    },
    {
      title: "Javascript (ES6)",
      value: 90
    },
    {
      title: "React",
      value: 60
    },
    {
      title: "HTML5",
      value: 90
    },
    {
      title: "Jest",
      value: 75
    },
    {
      title: "Sass",
      value: 70
    },
    {
      title: "Redux",
      value: 60
    },
    {
      title: "Python",
      value: 70
    },
    {
      title: "Java",
      value: 60
    },
    {
      title: "D3.js",
      value: 80
    },
  ],
  portfolios: [
    {
      id: 10,
      title:'Bam Digital',
      subtitle: "Mock-up of Bam Digital official website using React and Typescript",
      imageUrl:"/images/bamdigital-thumbnail.png",
      url: 'https://bam-digital-mock.vercel.app/',
    },
    {
      id: 9,
      title:'PLANRSVP',
      subtitle: "Guest reservation management web application for keeping track of guests",
      imageUrl:"/images/planrsvp-thumbnail.png",
      largeImageUrl: ["/images/planrsvp-large1.png", "/images/planrsvp-large2.png", "/images/planrsvp-large3.png"],
      url: 'https://github.com/LiuAllan/Event_Planner',
    },
    {
      id: 8,
      title:'Vizion',
      subtitle: "Website hosting a variety of data visualizations such as the 2020 Pandemic",
      imageUrl:"/images/vizion-thumbnail.png",
      largeImageUrl: ["/images/vizion-large1.png", "/images/vizion-large2.png", "/images/vizion-large3.png", "/images/vizion-large4.png", "/images/vizion-large5.png", "/images/vizion-large6.png"],
      url: 'https://vizion-henna.vercel.app/',
    },
    {
      id: 7,
      title:'RJC',
      subtitle: "Mock-up for RJC website using best SEO and UI/UX practices.",
      imageUrl:"/images/rjc-thumbnail.png",
      largeImageUrl: ["/images/rjc-large1.png", "/images/rjc-large2.png", "/images/rjc-large3.png", "/images/rjc-large4.png"],
      url: 'https://rjc-mockup.vercel.app/',
    },
    {
      id: 6,
      title: "Rebellion Barbershop",
      subtitle: "Webpage mockup for a local barbershop located in Victoria, BC.",
      imageUrl: "/images/rebellion.png",
      largeImageUrl: ["/images/rebellion-large.png", "/images/rebellion-large2.png", "/images/rebellion-large3.png"],
      url: 'https://rebellion-barber.vercel.app/'
    },    
    {
      id: 5,
      title: "Vancouver Housing",
      subtitle: "A data visualization on the increase of Vancouver's housing market.",
      imageUrl: "/images/vancouver-housing.png",
      largeImageUrl: ["/images/vancouver-housing-large.png", "/images/vancouver-housing-large2.png", "/images/vancouver-housing-large3.png" ],
      url: 'https://liuallan.github.io/Data-Visualization-VancouverHousingMarket/'
    },
    {
      id: 4,
      title: "Sick Fits Clothing",
      subtitle: "Full stack interactive store for selling and purchasing clothing.",
      imageUrl: "/images/sickfits.png",
      largeImageUrl: ["/images/sickfits-large.png", "/images/sickfits-large2.png", "/images/sickfits-large3.png", "/images/sickfits-large4.png" ],
      url: 'https://github.com/LiuAllan/Sick_Fits_Clothing'
    },
    {
      id: 3,
      title: "Fatal Police Shootings in the US",
      subtitle: "An interactive data visualization on fatal police shootings in the US.",
      imageUrl: "/images/police-shootings.png",
      largeImageUrl: ["/images/police-shootings-large.png"],
      url: 'https://dataviz-police-shootings.liuallan.vercel.app/'
    },
    {
      id: 2,
      title: "Yummy Recipes",
      subtitle: "Simple recipe finder that pulls data from an API.",
      imageUrl: "/images/yummy-recipe.png",
      url: 'https://yummy-recipes.vercel.app/'
    },
    {
      id: 1,
      title: "DayTrading Inc.",
      subtitle: "Software scalability solution for stock trading. Implemented distributed systems.",
      imageUrl: "/images/scalability.png",
      largeImageUrl: [
        "/images/scalability-large.png"
      ],
      url: 'https://github.com/LiuAllan/SENG468_ScalabilityDayTradeInc'
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 4,
        year: "2020 - Present",
        position: "Software Developer",
        company: "Reliable Controls",
        details: "As part of the Experience team, worked with UI/UX designers, Back-end and Full-stack developers, System Architect, Product owners and customers. Maintained a Web Component library built in Vue that is used in all Reliable Controls software products. Drafted, developed, and tested interactive charting and visualization project following best practices and standards." 
      },
      {
        id: 3,
        year: "2019 - 2019",
        position: "Quality Assurance Analyst Co-op",
        company: "ACD Systems",
        details: "Worked with Engineering to establish and verify benchmarks for program efficiency in operating and response time. Recommended modifications and additions to features of software being tested. Communicated with teams from different countries and time-zones. Identified and documented defects, questionable functions, and inconsistencies in software. Worked in an agile environment and resolved issues experienced by customers."
      },
      {
        id: 2,
        year: "2017 - 2018",
        position: "Client Support Technician Co-op",
        company: "BC Cancer Agency - Genome Sciences Center",
        details: "Worked with internal staff to troubleshoot computers, applications, and research equipment. Maintained, assembled, and upgraded new and existing lab instruments. Launched company phishing, privacy, and security awareness campaign. Documented work and implementations for technical and non-technical users."
      },
      {
        id: 1,
        year: "2017",
        position: "Competition Contestant",
        company: "BattleSnake",
        details: "Worked with a team of students to create a web-based Artificial Intelligence (AI) for the arcade game, 'Snake'. Effectively communicated with teammates and debugged programming errors with minimal supervision. Identified, approached, and solved problems systematically in an optimistic manner. Extensive use of version control and repository hosting services with Git and Github."
      },
    ],
    educationExperience: [
      {
        id: 2,
        year: "2013 - 2020",
        graduation: "Bachelor of Science, Computer Science and Software Systems",
        university: "University of Victoria",
        details: ""
      },
      {
        id: 1,
        year: "2010 - 2013",
        graduation: "British Columbia Certificate of Graduation",
        university: "Lambrick Park Secondary School",
        details: ""
      },
    ]
  },
  // blogs: [
  //   {
  //     id: 1,
  //     title: 'Markdown & Html supported blog.',
  //     featuredImage: '/images/blog-image-1.jpg',
  //     filesource: '../../blog/markdown-html-supported-blog.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 2,
  //     title: 'Installing NodeJS on your device.',
  //     featuredImage: '/images/blog-image-2.jpg',
  //     filesource: '../../blog/installing-nodejs-on-your-device.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  //   {
  //     id: 3,
  //     title: 'UI/UX design starter with Adobe XD.',
  //     featuredImage: '/images/blog-image-3.jpg',
  //     filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
  //     createDay: "20",
  //     createMonth: 'February',
  //     createYear: "2020"
  //   },
  // ],
  contactInfo: {
    phoneNumbers: [],
    emailAddress: ['allan.sf.liu@gmail.com'],
    location: "Victoria, British Columbia"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});